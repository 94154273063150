<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Manage Resources</h1>
      <div class="d-flex justify-content-end">
        <router-link class="ml-2" :to="{ name: 'BariResourceCategories'}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Category
          </el-button>
        </router-link>
        <router-link class="ml-2" :to="{ name: 'BariResourceSubCategories'}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Sub Category
          </el-button>
        </router-link>
        <router-link
          class="ml-2 "
          :to="{ name: 'StoreBariResource'}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>
      </div>
    </div>
    <div v-loading="loaders.bariResources" class="mt-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <base-input v-if="!selectedRows.length" class="d-inline-block" label="Search" placeholder="Search by title..."
                    v-model="request.title" type="search" @change="getAllBariResources" @search="getAllBariResources"/>
        <div v-else id="select-option-container" class="mt-4 ml-4">
          <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                       @click="promptMultiRemove"
                       :loading="loaders.checkboxButton.Delete"
                       :disabled="loaders.contentSection"
          >Delete
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :loading="loaders.checkboxButton.Activate"
                       :disabled="loaders.contentSection"
          >Activate
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :loading="loaders.checkboxButton.DeActive"
                       :disabled="loaders.contentSection"
          >Deactivate
          </base-button>
        </div>
      </div>
      <el-table-draggable
        handle=".handle"
        @drop="updateSortList"
      >
        <el-table
          class="table-responsive table-flush checklist-items-table checklist-table"
          header-row-class-name="thead-light"
          width="100%"
          ref="dynamicSectionItemListingTableRef"
          :data="response.bariResources"
          @selection-change="sectionListMultipleSelect"
        >
          <el-table-column align="left" :class-name="btnDraggable" width="50">
            <template slot-scope="scope">
              <div class="handle" v-if="scope.row.id !==''">
                <img src="/img/icons/buttons/handle.svg" alt="+">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="selected" type="selection" :min-width="2">
          </el-table-column>
          <el-table-column label="TITLE" :min-width="18">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column label="CATEGORY" :min-width="15">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                {{ scope.row.category ? scope.row.category.name : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="SUB CATEGORY" :min-width="15">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                {{ scope.row.sub_category ? scope.row.sub_category.name : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="RESOURCE" :min-width="25">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                <a :href="scope.row.link" target="_blank">{{ scope.row.link }}</a>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ENGAGEMENT TIME" :min-width="12">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.engagement_time ? scope.row.engagement_time + ' ' + scope.row.engagement_time_unit : 'N/A' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" :min-width="9">
            <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS" :min-width="9">
            <div slot-scope="{ $index, row }" class="d-flex">
              <el-tooltip
                class="item" effect="dark"
                content="Edit"
                placement="bottom-start"
                :visible-arrow="false"
              >
                <router-link
                  :to="{name: 'EditBariResource', params: {id: row.id}}">
                  <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
                </router-link>
              </el-tooltip>
              <el-tooltip
                class="item" effect="dark"
                content="Delete"
                placement="bottom-start"
                :visible-arrow="false"
              >
                <img
                  class="pointer"
                  @click="handleDelete($index, row)"
                  src="/img/icons/buttons/delete.svg"
                  alt="Delete"
                />
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeBariResource"
        @onRemoveMultiple="removeMultipleBariResources"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import BackButton from "@/components/Router/BackButton"
import ElTableDraggable from 'element-ui-el-table-draggable';

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "BariResources",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    ElTableDraggable,
    BackButton
  },
  data() {
    return {
      loaders: {
        bariResources: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      btnDraggable: 'btnDraggable',
      request: {
        title: ''
      },
      response: {
        bariResources: [],
      },
    }
  },
  mounted() {
    this.getAllBariResources()
  },
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  },
  methods: {
    /**
     * Get all Bari resources
     */
    getAllBariResources() {
      let vm = this
      vm.loaders.bariResources = true
      const payload = {
        title: vm.request.title
      }
      vm.$store.dispatch('BariResourceModule/_getAllBariResources', payload)
        .then(response => {
          vm.response.bariResources = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Bari Resources',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.bariResources = false
        })
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle the active button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle the deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Handle delete button event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Bari Resource.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove modal for multiple items
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Bari Resources.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle remove multiple items event
     */
    removeMultipleBariResources() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeBariResource(id) {
      this.deleteRequest(id)
    },

    // This function updates the Order of the table list
    updateSortList(event){
      let vm = this
      const ids = event.list.map(bariResource => bariResource.id)

      vm.loaders.bariResources = true
      vm.$store.dispatch('BariResourceModule/_updateSortingBariResource', {ids})
        .then(response => {
          vm.$notify.success({
            title: 'Bari Resources',
            message: 'Ordering updated successfully.'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Bari Resources',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.bariResources = false
        })
    },

    /**
     * Delete Bari Resource(s)
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.bariResources = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('BariResourceModule/_deleteBariResources', payload)
        .then(response => {
          vm.removeId = []
          vm.getAllBariResources()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: 'Bari Resource Deleted Successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Bari Resource',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.bariResources = false
        })
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.bariResources = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('BariResourceModule/_updateStatusBariResource', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updated Successfully.'
          })
          vm.getAllBariResources()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Bari Resource',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.bariResources = false
        })
    },
  }
}
</script>

<style scoped>

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;
}
</style>
